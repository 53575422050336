@import '../../../styles/variables';
@import '../../../styles/mixins';

.warningContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.warning {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
  padding: 12px;
  margin-bottom: 16px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  width: 100%;

  .warningIcon {
    width: 20px;
    height: 20px;
    fill: #856404;
  }

  span {
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
  }
}
