@import 'styles/variables';
@import 'styles/mixins';

.analysis-landing-content-error {
  @include flex(row, center, center);
  margin-top: 56px;

  b {
    display: inline;
  }
}

.analysis-landing-content-loading {
  @include flex(column, center, center);
  height: calc(100vh - 200px); // Account for header/margins
  text-align: center;
  gap: 16px;

  span {
    font-size: 18px;
  }
}
