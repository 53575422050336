@import 'styles/variables';
@import 'styles/mixins';

.profile-menu {
  @include flex(row, center, space-between);

  svg {
    @include size(20px);
  }

  &__avatar {
    @include size(44px);

    border-radius: 50%;
  }

  &__header {
    @include flex(row, center, flex-start, nowrap);

    padding: 1rem 1.2rem 0.8rem 1rem;

    div {
      margin-left: 8px;

      p {
        margin-bottom: 2px;
        font-size: $font-size--base;

        &:last-child {
          font-size: $font-size--xs;
          color: $text-grey;
        }
      }
    }
    a {
      color: $brand-color;
    }
  }

  &__header &__avatar {
    @include size(32px);
  }

  @media #{$media-mobile} {
    @include position(absolute, unset, 90px);
    height: 48px;
    padding: 0 20px;

    svg {
      @include size(16px);
    }

    &__avatar {
      @include size(32px);
    }
  }
}
