@import 'styles/variables';
@import 'styles/mixins';

.purchase-modal {
  @include default-modal();
  a {
    color: $navlink-color;
  }
  margin: auto;
  min-width: 750px;
  max-width: 750px;
  min-height: 300px;
  overflow: hidden;

  &__header {
    @include flex-center();
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: $border-radius--md $border-radius--md 0 0;
    border-bottom: 1px solid $input-border-color;
    background-color: $button-bg;

    h1 {
      font-weight: $font-weight--bold;
      font-size: $font-size--md;
      margin: 0;
      color: $brand-color--tertiary;
      text-align: center;
    }

    svg {
      height: 20px;
      width: 20px;
      color: $input-border-color;
    }
  }

  &__content,
  &__footer {
    padding: 8px 10px;
    box-sizing: border-box;
  }

  &__purchase-options {
    h1 {
      font-weight: $font-weight--bold;
      font-size: $font-size--xxl;
      margin: 5px;
      color: $brand-color--tertiary;
    }
    h2 {
      font-weight: $font-weight--bold;
      font-size: $font-size--lg;
      margin: 5px;
      color: $brand-color--tertiary;
    }
    p {
      font-size: $font-size--sm;
      font-style: italic;
      text-align: center;
      margin: 5px;
    }
    display: flex;
    resize: horizontal;
    gap: 8px;
    margin: 10px 5px;
    flex-wrap: wrap;
    min-height: 260px;

    &__option {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 12px;
      line-height: 25px;
      border: 1px solid $input-border-color;
      border-radius: $border-radius--sm;
      box-sizing: border-box;
      flex: 1 1 calc(33% - 8px);
      p {
        line-height: 15px;
      }

      &__list-items {
        margin: 8px 0 0;
        font-size: $font-size--sm;
        list-style-type: disc;
      }

      &__button {
        align-self: flex-end;
        margin-top: auto;
      }
    }
    &__box {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
      margin-bottom: 0;
      margin-top: 0;
      align-items: center;
      padding: 12px;
      border: 3px solid $input-border-color;
      border-radius: $border-radius--sm;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      font-size: $font-size--lg;
      box-sizing: border-box;
      flex: 1 1 calc(33% - 8px);
      max-width: 360px;
      min-width: 360px;
      height: 70px;
      &:hover {
        border: 4px solid $brand-color--tertiary;
        border-radius: $border-radius--md;
        cursor: pointer;
      }
    }
    &__box-selected {
      border: 4px solid $brand-color--tertiary;
      border-radius: $border-radius--md;
    }
  }

  &__choice-error {
    margin-top: 8px;
    font-size: $font-size--sm;
    color: $error-color;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 0px;
    border-top: 1px solid $input-border-color;

    button {
      margin-left: 8px;
      padding: 4px 12px;
    }

    a {
      margin-top: 10px;
    }

    &--disabled {
      opacity: 0.5;
    }
  }
  &__sub-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 5px;
      padding: 4px 12px;
    }

    &--disabled {
      opacity: 0.5;
    }
  }
  @media #{$media-mobile} {
    min-width: fit-content;
  }
}
