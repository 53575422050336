/* Typography variables */

$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$button-font-family: 'Arial', sans-serif;
$pre-font-family: 'monospace';
$nav-font-family: 'Source Code Pro', serif;
$font-weight--normal: 400;
$font-weight--medium: 600;
$font-weight--bold: 700;

$font-size--xs: 11px;
$font-size--sm: 14px;
$font-size--base: 15px;
$font-size--md: 16px;
$font-size--lg: 18px;
$font-size--xl: 20px;
$font-size--xxl: 24px;
$font-size-xxxl: 28px;

/* Color Variables */
$white: #ffffff;
$primary-color: #000000de;

$brand-color: #007f66;
$brand-color--secondary: #00735c;
$brand-color--tertiary: #005645;

$table-row-bg: rgba(12, 134, 46, 0.4);
$table-row-bg--light: rgba(12, 134, 46, 0.2);

$text-grey: #808080de;

$bg-light: #edf2f7;
$bg-light--secondary: #e2e8f0;
$bg-light--tertiary: #cbd5e0;
$bg-dark: rgba(0, 0, 0, 0.1);
$bg-dark--secondary: rgba(0, 0, 0, 0.15);
$bg-dark--tertiary: rgba(0, 0, 0, 0.2);

$button-bg: #eeeeee;
$tooltip-bg: #1a202c;
$table-header-bg: #d9d9d9;

$input-border-color: #bdbdbd;
$error-color: #e53e3e;
$link-color: #3182ce;
$navlink-color: hsla(168.2, 62.89%, 19.02%, 1);
$navlink-color--active: #999999;

$modal-overlay: rgba(0, 0, 0, 0.6);
$form-element-outline: #64b5f6;

/* Box shadow variables */
$header-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$button-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$button-shadow--brand: 0px 4px 4px rgba(0, 0, 0, 0.3);

/* Border radius variables */
$border-radius--sm: 4px;
$border-radius--md: 6px;
$border-radius--lg: 8px;

/* Media query variables */
$media-desktop: 'only screen and (max-width : 1200px)';
$media-desktop--sm: 'only screen and (max-width : 1024px)';
$media-desktop--short: 'only screen and (max-height : 900px)';
$media-tablet: 'only screen and (max-width : 768px)';
$media-mobile: 'only screen and (max-width : 600px)';
$media-mobile--sm: 'only screen and (max-width : 480px)';

/* Social login variables */
$icon-list: (facebook '\f09a'#4b70ab, google '\f1a0'#e34133);
$social-btn-list: (
  facebook 'https://cdn.cidaas.de/images/socialicons/facebook.svg' #4b70ab
    #ffffff 8px 12px 4px 600,
  google 'https://cdn.cidaas.de/images/socialicons/google.svg' #ffffff #5f6368
    8px 12px 4px 600
);
