@import 'styles/variables';
@import 'styles/mixins';

.copy-trial-modal {
  @include default-modal();

  &__header {
    @include flex-center();

    justify-content: center;
    border-top-left-radius: $border-radius--md;
    border-top-right-radius: $border-radius--md;
    border-bottom: 2px solid $input-border-color;
    background-color: $button-bg;

    h1 {
      font-weight: $font-weight--bold;
      font-size: $font-size--lg;
    }

    svg {
      height: 24px;
      width: 24px;
      color: $input-border-color;
    }
  }

  &__header,
  &__content,
  &__footer {
    padding: 10px;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    &__button {
      margin-top: 16px;
      padding: auto;
      max-width: fit-content;
      min-width: 50px;
    }
  }

  &__copy-trial-modal-error {
    margin-top: 16px;
    font-size: $font-size--base;
    color: $error-color;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $button-bg;

    button {
      margin-left: 16px;
      padding: 0 16px;
    }

    &--disabled {
      opacity: 0.6;
    }
  }
}
